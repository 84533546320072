.sc-fzqARJ {
    display: none !important;
}


.card-header{
   cursor: pointer; 
}

.scroll-new{
    max-height: 80vh;
    overflow-y: scroll;
}

.scroll-answer{
    max-height: 44vh;
    overflow-y: scroll;
}

.scroll-notification{
    max-height: 47vh;
    overflow-y: scroll;
    padding: 5px;
}

.buttonColor{
background-color: #CF95C2 !important;
}
.radiobutton{
    z-index:0 !important;
}

.auth-wrapper.auth-v1 .auth-inner{
    max-width: 500px !important;
}

.cardStyle{
    border: 1px solid lightgray !important;
}

@font-face {
    font-family: 'Helvetica';
    src: url('../font/HelveticaBold.ttf'); /* IE9 Compat Modes */
    font-weight: bolder;
   
  }


  
@font-face {
    font-family: 'HelveticaNowDisplayBold';
    src: url('../font/HelveticaNowDisplayBold.ttf'); /* IE9 Compat Modes */
    font-weight: bolder;
   
  }

  .boldFont{
    font-family:'HelveticaNowDisplayBold' ;
}

  body{
      font-family:'Helvetica' ;
  }


  .fontHelveticaBold{
font-family: 'HelveticaNowDisplayBold';
color: #8D6584;
  }

  .fontHelvetica{
font-family: 'Helvetica';
color: #8D6584;

  }

  .faqCard{
     font-family: 'fontHelvetica' !important;
     font-size: medium !important;
}
.faqCardBold{
    font-family: 'HelveticaNowDisplayBold' !important;
}

  .badgeColor{
      background-color: #82868b !important;
  }

  .loginCardStyle{
      background-color:rgba(0, 0, 0, 0.25);
      padding: 15px;
      width: 50%;
      height: 100%;
  }

  .Button_Style{
      width: 90px;
  }

  .deneme{
      margin-right: 20px !important;
  }
  .Card_Style{
      margin-top: 30px;
      margin-right: 8px;
      width: 90px;
  }
  
@media only screen and (max-width: 767px) {
    .loginCardStyle{
        background-color:rgba(0, 0, 0, 0.25);
        padding: 15px;
        width: 100%;
        height: 100%;
    }
  }

  @media only screen and (min-width: 767px) and (max-width: 1023px) {


    .loginCardStyle{
        width:100%
    }

    .mervis{
        justify-content: center;
    }
  }